import {createSlice} from '@reduxjs/toolkit';

const initialState = {
    status: 'loading',
    page: 0,
    dataFilter: {},
    productCategories: [],
    productId: '',
    orders:  localStorage.orders ? JSON.parse(localStorage.orders) : []
};

export const appSlice = createSlice({
    name: 'app',
    initialState,
    reducers: {
        setProduct: (state, action) => {
            state.productId = action.payload;
        },
        setPage: (state, action) => {
            state.page = action.payload;
        },
        setDataFilter: (state, action) => {
            state.dataFilter = action.payload;
        },
        addOrder: (state, action) => {
            const order = action.payload;
            state.orders = [...state.orders, order];
            localStorage.setItem('orders', JSON.stringify(state.orders))
        },
        removeOrder: (state, action) => {
            const productId = action.payload;
            state.orders = state.orders.filter(order => order.productId !== productId);
            localStorage.setItem('orders', JSON.stringify(state.orders))
        },
        refreshOrder: (state, action) => {
            const order = action.payload;
            state.orders = [...state.orders.filter(v => v.productId !== order.productId), order];
            localStorage.setItem('orders', JSON.stringify(state.orders))
        },
        resetOrders: (state, action) => {
            state.orders = [];
            localStorage.setItem('orders', [])
        }
    }
});

export const {
    setPage,
    setDataFilter,
    setProduct,
    addOrder,
    refreshOrder,
    removeOrder,
    resetOrders,
} = appSlice.actions;

export const selectPage = (state) => state.app.page;
export const selectDataFilter = (state) => state.app.dataFilter;
export const selectProductId = (state) => state.app.productId;
export const selectOrders = (state) => state.app.orders;

export const loadProduct = (id) => (dispatch, getState) => {
    dispatch(setProduct(id));
};

export default appSlice.reducer;
