export const text_1 = 'НА ПРЕДПРИЯТИЕ ТРЕБУЮТСЯ:';
export const text_2 = 'ЗАКРОЙЩИКИ';
export const text_3 = 'ШВЕИ';
export const text_4 = 'КЛАДОВЩИК';
export const text_5 = 'НАБИВЩИК РИСУНКА';
export const text_6 = 'СПЕЦИАЛИСТ ПО ВЛАЖНОЙ ТЕПЛОВОЙ ОБРАБОТКЕ';
export const text_7 = 'УПАКОВЩИК';
export const text_8 = 'СЛЕСАРЬ ПО РЕМОНТУ ШВЕЙНОГО ОБОРУДОВАНИЯ';
export const text_9 = 'УБОРЩИЦА';
export const text_10 = 'ГРУЗЧИК';
export const text_11 = 'ГРАФИК РАБОТЫ С 7,00 до 15,30';
export const text_12 = 'ПОЛНЫЙ СОЦПАКЕТ, ДОСТОЙНАЯ ОПЛАТА ТРУДА';
export const text_13 = 'ПРЕДВАРИТЕЛЬНОЕ СОБЕСЕДОВАНИЕ ПО ТЕЛЕФОНУ  79493559028';
export const text_14 = 'ВОЗМОЖНО ОБУЧЕНИЕ ЗА СЧЕТ ПРЕДПРИЯТИЯ';