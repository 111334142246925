import React from 'react';
import {Col, ListGroup, Row} from "react-bootstrap";
import {
    text_4_1, text_4_2,
    text_4_2_1,
    text_4_2_2, text_4_3, text_4_4, text_4_5, text_4_6, text_4_7
} from "../../app/text_contacts";
import {
    text_1, text_10, text_11, text_12, text_13, text_14,
    text_1_0,
    text_1_1,
    text_2,
    text_3,
    text_4,
    text_5,
    text_6,
    text_7,
    text_8, text_9
} from "../../app/text_vacancies";

function VacanciesPage() {
    return (
        <>
            <Row className="justify-content-md-center">
                <Col md="auto">
                    <div className='vov'>
                        <Row><h2>{text_1}</h2></Row>
                        <hr/>
                        <Row><h4>{text_2}</h4></Row>
                        <Row><h4>{text_3}</h4></Row>
                        <Row><h4>{text_4}</h4></Row>
                        <Row><h4>{text_5}</h4></Row>
                        <Row><h4>{text_6}</h4></Row>
                        <Row><h4>{text_7}</h4></Row>
                        <Row><h4>{text_8}</h4></Row>
                        <Row><h4>{text_9}</h4></Row>
                        <Row><h4>{text_10}</h4></Row>
                        <hr/>
                        <Row><h2>{text_11}</h2></Row>
                        <Row><h2>{text_12}</h2></Row>
                        <Row><h2>{text_13}</h2></Row>
                        <Row><h2>{text_14}</h2></Row>
                    </div>
                </Col>
            </Row>
        </>
    );
}

export default VacanciesPage;
