export const text_1_1 = 'Основные условия работы'
export const text_1_2 = 'Сбор заказа до 3 дней , отправка товара в течении 1 рабочего дня после оплаты.'
export const text_1_3 = 'Отгрузка товара: Донецк, Макеевка  каждый четверг.'
export const text_1_4 = 'Бесплатная доставка до филиала почты России .'
export const text_1_5 = 'Осуществляем отгрузку заказов по России.'
export const text_1_6 = 'Минимальный заказ от 10 000 руб.'
export const text_1_7 = 'Товар упакован поштучно.'
export const text_1_8 = 'Продаем без размерных рядов.'

export const text_2_1 = 'СИСТЕМА СКИДОК'
export const text_2_2 = '10 000 3%'
export const text_2_3 = '20 000 5%'
export const text_2_4 = '35 000 7%'
export const text_2_5 = '50 000 10%'
export const text_2_6 = '100 000 15%'


export const text_3_1 = 'Причины стать нашим клиентом:'

export const text_3_2_1 = '1. Привлекательный ассортимент:'
export const text_3_2_2 = 'Более 1500 моделей'
export const text_3_2_3 = 'Для детей и взрослых'
export const text_3_2_4 = 'Новинки каждую неделю'
export const text_3_2_5 = 'Постоянное обновление тканей'
export const text_3_2_6 = 'Собственный  цех трафаретной печати'

export const text_3_3_1 = '2. Выгодные условия'
export const text_3_3_2 = 'Минимальный заказ от 10000 руб.'
export const text_3_3_3 = 'Быстрый сбор заказов'
export const text_3_3_4 = 'Гибкая система скидок'
export const text_3_3_5 = 'Непрерывные акции'
export const text_3_3_6 = 'Доставка во все регионы РФ'

export const text_3_4_1 = '3. Надежный партнер'
export const text_3_4_2 = 'Работаем с 2011 года'
export const text_3_4_3 = 'Вся продукция сертифицирована и прошла испытания'
export const text_3_4_4 = 'Высокое качество турецкого полотна'
export const text_3_4_5 = 'Неизменно приятные цены'

