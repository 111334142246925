import Card from 'react-bootstrap/Card';
import {Col, Modal, Row} from "react-bootstrap";
import React from "react";

function ImageViewModal({mediaLink, ...props}) {
    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Body>
                <Row>
                    <Col key={mediaLink}>
                        <Card>
                            <Card.Img variant="top" src={mediaLink}/>
                        </Card>
                    </Col>
                </Row>
            </Modal.Body>
        </Modal>
    );
}

export default ImageViewModal;