import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import {useSelector} from "react-redux";
import {Form} from "react-bootstrap";
import logo_main from "../../../logo_main.svg"
import {
    menu_btn_1,
    menu_btn_2,
    menu_btn_3,
    menu_btn_4,
    menu_btn_5,
    menu_btn_6,
    menu_btn_7
} from "../../../app/text_home";
import {Cart} from "react-bootstrap-icons";
import {selectOrders} from "../../../app/appSlice";
import React from "react";
import IconButton from "../../../components/kit/IconButton";
import {Link} from 'react-router-dom'

function HeaderNavbar() {
    const orders = useSelector(selectOrders);

    function renderCartPageButton() {
        return (
            <IconButton icon={<Cart/>}
                        isBadge={true}
                        content={orders.length}
                        disabled={orders.length === 0}
                        as={Link} to="/cart"
            />
        )
    }

    return (
        <div className="vov">
            <Navbar expand="lg" bg="none">
                <Container fluid>
                    <Navbar.Brand as={Link} to="/">
                        <img
                            alt=""
                            src={logo_main}
                            height="80"
                            className="d-inline-block align-top"
                            style={{cursor: 'pointer'}}
                        />{' '}
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="navbarScroll"/>
                    <Navbar.Collapse id="navbarScroll">
                        <Nav
                            className="me-auto my-2 my-lg-0"
                            style={{maxHeight: '100px'}}
                            navbarScroll
                        >
                            <Nav.Link variant="outline-primary" as={Link} to="/about">{menu_btn_1}</Nav.Link>
                            <Nav.Link variant="outline-primary" as={Link} to="/catalog">{menu_btn_2}</Nav.Link>
                            <Nav.Link variant="outline-primary" as={Link} to="/partnership">{menu_btn_3}</Nav.Link>
                            <Nav.Link variant="outline-primary" as={Link} to="/delivery">{menu_btn_4}</Nav.Link>
                            <Nav.Link variant="outline-primary" as={Link} to="/certificates">{menu_btn_6}</Nav.Link>
                            <Nav.Link variant="outline-primary" as={Link} to="/contacts">{menu_btn_5}</Nav.Link>
                            <Nav.Link variant="outline-primary" as={Link} to="/vacancies">{menu_btn_7}</Nav.Link>
                        </Nav>
                        <Form className="d-flex">
                            {/*<Form.Control*/}
                            {/*    type="search"*/}
                            {/*    placeholder="Search"*/}
                            {/*    className="me-2"*/}
                            {/*    aria-label="Search"*/}
                            {/*/>*/}
                            {renderCartPageButton()}
                        </Form>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </div>
    );
}

export default HeaderNavbar;