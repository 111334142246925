import React from 'react';
import {
    text_1_1,
    text_1_2,
    text_1_3,
    text_1_4,
    text_2_1,
    text_2_2,
    text_2_3, text_2_4,
    text_3_1, text_3_2,
    text_4_1, text_4_10, text_4_2, text_4_3, text_4_4, text_4_5, text_4_6, text_4_7, text_4_8, text_4_9
} from "../../app/text_delivery";
import {Container, Row, Stack} from "react-bootstrap";

function DeliveryPage() {

    function renderHeadText(text) {
        return (
            <h2>{text}</h2>
        )
    }

    function renderRegularText(text) {
        return (
            <h4>{text}</h4>
        )
    }

    return (
        <Container>
            <Stack gap={3}>
                <Row>
                    <div className="vov">
                        {renderHeadText(text_1_1)}
                        <hr/>
                        {renderRegularText(text_1_2)}
                        {renderRegularText(text_1_3)}
                        {renderRegularText(text_1_4)}
                    </div>
                </Row>
                <Row>
                    <div className="vov">
                        {renderHeadText(text_2_1)}
                        <hr/>
                        {renderRegularText(text_2_2)}
                        {renderRegularText(text_2_3)}
                        {renderRegularText(text_2_4)}
                    </div>
                </Row>
                <Row>
                    <div className="vov">
                        {renderHeadText(text_3_1)}
                        <hr/>
                        {renderRegularText(text_3_2)}
                    </div>
                </Row>
                <Row>
                    <div className="vov">
                        {renderHeadText(text_4_1)}
                        <hr/>
                        {renderRegularText(text_4_2)}
                        {renderRegularText(text_4_3)}
                        {renderRegularText(text_4_4)}
                        {renderRegularText(text_4_5)}
                        {renderRegularText(text_4_6)}
                        {renderRegularText(text_4_7)}
                        {renderRegularText(text_4_8)}
                        {renderRegularText(text_4_9)}
                        {renderRegularText(text_4_10)}
                    </div>
                </Row>
            </Stack>
        </Container>
    );
}

export default DeliveryPage;
