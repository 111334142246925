import React from 'react';
import {
    text_1_1,
    text_1_2,
    text_1_3,
    text_1_4,
    text_1_5,
    text_1_6,
    text_1_7,
    text_1_8,
    text_2_1,
    text_2_2,
    text_2_3,
    text_2_4,
    text_2_5,
    text_2_6,
    text_3_1,
    text_3_2_1,
    text_3_2_2,
    text_3_2_3,
    text_3_2_4,
    text_3_2_5,
    text_3_2_6,
    text_3_3_1,
    text_3_3_2,
    text_3_3_3,
    text_3_3_4,
    text_3_3_5,
    text_3_3_6,
    text_3_4_1,
    text_3_4_2, text_3_4_3, text_3_4_4, text_3_4_5
} from "../../app/text_partnership";
import {Container, Row, Stack} from "react-bootstrap";


function PartnershipPage() {

    function renderHeadText(text) {
        return (
            <h2>{text}</h2>
        )
    }

    function renderRegularText(text) {
        return (
            <h4>{text}</h4>
        )
    }

    return (
        <Container>
            <Stack gap={3}>
                <Row>
                    <div className="vov">
                        {renderHeadText(text_1_1)}
                        <hr/>
                        {renderRegularText(text_1_2)}
                        {renderRegularText(text_1_3)}
                        {renderRegularText(text_1_4)}
                        {renderRegularText(text_1_5)}
                        {renderRegularText(text_1_6)}
                        {renderRegularText(text_1_7)}
                        {renderRegularText(text_1_8)}
                    </div>
                </Row>
                <Row>
                    <div className="vov">
                        {renderHeadText(text_2_1)}
                        <hr/>
                        {renderRegularText(text_2_2)}
                        {renderRegularText(text_2_3)}
                        {renderRegularText(text_2_4)}
                        {renderRegularText(text_2_5)}
                        {renderRegularText(text_2_6)}
                    </div>
                </Row>
                <Row>
                    <div className="vov">
                        {renderHeadText(text_3_1)}
                        <hr/>
                        {renderRegularText(text_3_2_1)}
                        {renderRegularText(text_3_2_2)}
                        {renderRegularText(text_3_2_3)}
                        {renderRegularText(text_3_2_4)}
                        {renderRegularText(text_3_2_5)}
                        {renderRegularText(text_3_2_6)}
                        <hr/>
                        {renderRegularText(text_3_3_1)}
                        {renderRegularText(text_3_3_2)}
                        {renderRegularText(text_3_3_3)}
                        {renderRegularText(text_3_3_4)}
                        {renderRegularText(text_3_3_5)}
                        {renderRegularText(text_3_3_6)}
                        <hr/>
                        {renderRegularText(text_3_4_1)}
                        {renderRegularText(text_3_4_2)}
                        {renderRegularText(text_3_4_3)}
                        {renderRegularText(text_3_4_4)}
                        {renderRegularText(text_3_4_5)}
                    </div>
                </Row>
            </Stack>
        </Container>
    );
}

export default PartnershipPage;
