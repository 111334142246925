import React from 'react';
import {Accordion, Image, Row, Stack} from "react-bootstrap";
import {
    text_2_1,
    text_2_2,
    text_2_3,
    text_2_4,
    text_2_5,
    text_2_6,
    text_2_7,
    text_2_8,
    text_2_9
} from "../../app/text_home";

function AboutPage() {
    return (
        <Stack gap={3}>
            <Row>
                <Image
                    src='https://res.cloudinary.com/dy7ic6yp5/image/upload/v1728561322/media/hpi3hfrvvk24vgrryb5l.jpg'
                    fluid/>
            </Row>
            <Row>
                <h4>{text_2_1}</h4>
            </Row>
            <Row>
                <Accordion defaultActiveKey="0">
                    <Accordion.Item eventKey="0">
                        <Accordion.Header><h3>{text_2_2}</h3></Accordion.Header>
                        <Accordion.Body><h4>{text_2_3}</h4></Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                        <Accordion.Header><h3>{text_2_4}</h3></Accordion.Header>
                        <Accordion.Body>
                            <h4>{text_2_5}</h4>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2">
                        <Accordion.Header><h3>{text_2_6}</h3></Accordion.Header>
                        <Accordion.Body>
                            <h4>{text_2_7}</h4>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="3">
                        <Accordion.Header><h3>{text_2_8}</h3></Accordion.Header>
                        <Accordion.Body>
                            <h4>{text_2_9}</h4>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </Row>
        </Stack>
    );
}

export default AboutPage;
