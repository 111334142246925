import {ButtonGroup, Container, Row} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import {ArrowLeftSquare, ArrowRightSquare} from "react-bootstrap-icons";
import Navbar from "react-bootstrap/Navbar";
import React from "react";

const areEqual = (prevProps, nextProps) => {
    return nextProps.loadingStatus;
};

const CatalogPagination = ({
                               page = 0,
                               loadingStatus,
                               totalPages = 0,
                               onNext = () => {
                               },
                               onPrevious = () => {
                               },
                               onPageSelect = () => {
                               },
                               isFirstPage = true,
                               isLastPage = true
                           }) => {

    function renderPageButtons(totalPages) {
        const buttons = [];
        for (let i = 0; i < totalPages; i++) {
            buttons.push(<Button
                key={i}
                active={i === page}
                onClick={() => onPageSelect(i)}>{i + 1}
            </Button>)
        }
        return buttons;
    }

    return (
        <div className="vov">
            <Navbar expand="lg" bg="none" className="justify-content-center">
                <Row>
                    <Container>
                        <ButtonGroup>
                            <Button
                                disabled={isFirstPage}
                                onClick={onPrevious}>
                                <ArrowLeftSquare/>
                            </Button>
                            {renderPageButtons(totalPages)}
                            <Button
                                disabled={isLastPage}
                                onClick={onNext}>
                                <ArrowRightSquare/>
                            </Button>
                        </ButtonGroup>
                    </Container>
                </Row>
            </Navbar>
        </div>
    );
};

export default React.memo(CatalogPagination, areEqual);