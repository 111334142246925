import React from 'react';
import {Col, Container, Image, Row} from "react-bootstrap";
import {text_5_1, text_5_2, text_5_3, text_5_4} from "../../app/text_home";
import {Telephone, Telegram, Envelope} from "react-bootstrap-icons";
import logo from "../../logo.svg";
import img from "../../img.png";

function Footer() {
    return (
        <Container>
            <Row>
                <div className='vov-primary'>

                    <Col>
                        <Row className="justify-content-center">
                            <Col md={2}>
                                <Image src={logo} fluid/>
                            </Col>
                        </Row>
                        <Row className="justify-content-center">
                            <Col md="auto">
                                <Row><Col>{text_5_2}</Col></Row>
                            </Col>
                        </Row>
                        <Row className="justify-content-center">
                            <Col xs="auto">
                                <Row>
                                    <Col xs={2}><Telephone/></Col>
                                    <Col>{text_5_1}</Col>
                                </Row>
                                <Row>
                                    <Col xs={2}><Telegram/></Col>
                                    <Col xs={10}><a
                                        href={text_5_4}
                                        style={{color:'#6EA8FE'}}
                                        target='_blank'>{text_5_4}</a>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={2}><Envelope/></Col>
                                    <Col>{text_5_3}</Col>
                                </Row>
                            </Col>
                            <Col xs="6" md="4" lg="2">
                                <Image src={img} fluid/>
                            </Col>
                        </Row>
                    </Col>
                </div>
            </Row>
        </Container>
    );
}

export default Footer;
