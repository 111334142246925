import React, {useState} from 'react';
import {Badge, Col, Container, Form, Image, InputGroup, Row, Spinner, Stack} from "react-bootstrap";
import {Telephone, Pencil, Envelope} from "react-bootstrap-icons";
import {useDispatch, useSelector} from "react-redux";
import {resetOrders, selectOrders} from "../../app/appSlice";
import Navbar from "react-bootstrap/Navbar";
import Button from "react-bootstrap/Button";
import IconButton from "../../components/kit/IconButton";
import {loadProduct} from "../../app/appSlice";
import {useMutation, useQuery} from "@apollo/client";
import {ORDER_MUTATION, PRODUCTS_CART_QUERY} from "../../app/graphql";
import {
    text_3_0,
    text_3_1,
    text_3_2,
    text_3_3,
    text_3_4, text_3_5,
    text_7_3,
    text_7_4,
    text_7_5,
    text_7_6,
    text_7_7
} from "../../app/text_cart";
import {mapValueReviver} from "../../app/utils";
import generatePDF, {Margin, Resolution, usePDF} from "react-to-pdf";
import {Link} from "react-router-dom";
import ServerCallAlert from "../../components/ServerCallAlert";
import {text_1_0, text_1_1} from "../../app/text_basic";
import logo_main from "../../logo_main.svg";
import Nav from "react-bootstrap/Nav";
import {menu_btn_1, menu_btn_2, menu_btn_3, menu_btn_4, menu_btn_5, menu_btn_6} from "../../app/text_home";

const generatePdfOptions = {
    filename: "order.pdf",
    method: "build",
    resolution: Resolution.NORMAL,
    page: {
        margin: Margin.SMALL,
        format: "A4",
        orientation: "portrait",
    },
    canvas: {
        mimeType: "image/jpeg",
        qualityRatio: 1,
    },
    overrides: {
        pdf: {
            compress: true,
        },
        canvas: {
            useCORS: true,
        },
    },
};

function CartPage() {
    const dispatch = useDispatch();
    const ordersItems = useSelector(selectOrders);
    const [userName, setUserName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [sendingOrder, setSendingOrder] = useState(false);
    const [submitOrder, {loading: submitLoading, error: submitError, data: submitData}] = useMutation(ORDER_MUTATION);
    const {loading: pageLoading, error: pageLoadingError, data: pageData} = useQuery(PRODUCTS_CART_QUERY, {
        variables: {input: {pageInput: {page: 0, size: 99999}, ids: ordersItems.map(oi => oi.productId)}},
    });

    const {toPDF, targetRef} = usePDF({filename: 'order.pdf'});

    function resetState() {
        setUserName('');
        setEmail('');
        setPhone('');
        setSendingOrder(false);
        dispatch(resetOrders());
    }

    function updateName(e) {
        setUserName(e.target.value);
    }

    function updateEmail(e) {
        setEmail(e.target.value);
    }

    function updatePhone(e) {
        setPhone(e.target.value);
    }

    const uploadOrderPdf = (file) => {
        const formData = new FormData();
        formData.append("file", file);
        formData.append("cloud_name", 'dy7ic6yp5');
        formData.append("upload_preset", 'e0bwovx1');

        return fetch(
            "https://api.cloudinary.com/v1_1/dy7ic6yp5/image/upload/",
            {
                'method': 'POST',
                body: formData,
            }
        ).then(res => {
            toPDF();
            resetState();
            return res.json();
        });
    };

    function sendOrder(assetId) {
        const array = [];
        ordersItems.map(oi => {
            for (const [key1, value1] of JSON.parse(oi.selected, mapValueReviver)) {
                for (const [key2, value2] of value1) {
                    array.push({sizeId: key2, amount: Number.parseInt(value2)})
                }
            }
        })

        const vars = {
            variables: {
                input: {
                    userName,
                    phone,
                    email,
                    orderPdfAssetId: assetId,
                    orderItems: array
                }
            }
        };
        submitOrder(vars);
    }

    const getTargetElement = () => document.getElementById("pdf-source");

    const createOrder = () => {
        setSendingOrder(true);
        generatePDF(getTargetElement, generatePdfOptions)
            .then(jsPdf => uploadOrderPdf(new File([jsPdf.output('blob')], "order.pdf")))
            .then(pdfFile => sendOrder(pdfFile.url))
    };

    function renderSize(size, amount) {
        return (
            <Col sm='auto'>
                <InputGroup className="mb-3">
                    <InputGroup.Text>
                        <Badge bg='primary'>{size.size}</Badge>
                    </InputGroup.Text>
                    <InputGroup.Text>
                        {amount}
                    </InputGroup.Text>
                </InputGroup>
            </Col>
        )
    }

    function renderSizes(assetSizes, selectedSizes) {
        const array = [];
        for (const [key, value] of selectedSizes) {
            array.push(renderSize(assetSizes.filter(as => as.id === key)[0], value))
        }
        return array;

    }

    function renderAsset(asset, selectedSizes) {
        return (
            <Row>
                <Col md={4} sm={6}>
                    <Image thumbnail fluid
                           style={{backgroundColor: "#fdebf4"}}
                           src={asset.mediaUrl}/>
                </Col>
                <Col md={8} sm={6}>
                    <Row>
                        {renderSizes(asset.sizes, selectedSizes)}
                    </Row>
                </Col>
            </Row>
        )
    }

    function renderAssets(selectedAssets, productAssets) {
        const array = [];
        for (const [key, value] of selectedAssets) {
            array.push(renderAsset(productAssets.filter(pa => pa.id === key)[0], value))
        }
        return array;
    }

    function renderOrder(order, index) {
        const product = pageData.products.content.filter(p => p.id === order.productId)[0] || {}
        const productName = product.name || ''
        const productArticle = product.article || ''
        const productAssets = product.assets || []
        const selectedAssets = JSON.parse(order.selected, mapValueReviver)

        return (

            <Row key={index} className="justify-content-md-center">
                <Col lg={6}>
                    <div className='vov'>
                        <Row className="justify-content-between">
                            <Col md="auto">
                                <Row>
                                    <Col md="auto"><h3>{productName}</h3></Col>
                                    <Col md="auto">
                                        <h3><Badge bg="primary"> {productArticle} </Badge></h3>
                                    </Col>
                                </Row>
                            </Col>
                            <Col md="auto">
                                <IconButton icon={<Pencil/>}
                                            content={text_7_6}
                                            onClick={() => dispatch(loadProduct(order.productId))}
                                            as={Link} to={`/product/${order.productId}`}/>
                            </Col>
                        </Row>
                        <hr/>
                        <Row>
                            <Stack gap={3}>
                                {renderAssets(selectedAssets, productAssets)}
                            </Stack>
                        </Row>
                        <hr/>
                    </div>
                </Col>
            </Row>

        )
    }

    if (submitLoading || pageLoading)
        return (
            <div className='vov'>
                <Row>
                    <Col md="auto">
                        <Spinner animation="border" role="status">
                            <span className="visually-hidden">Sending...</span>
                        </Spinner>
                    </Col>
                    <Col>
                        <h3>{submitLoading ? text_3_4 : pageLoading ? text_3_5 : ''}</h3>
                    </Col>
                </Row>
            </div>
        );
    if (pageLoadingError) return <ServerCallAlert message={text_1_0}/>
    if (submitError) return <ServerCallAlert message={text_1_1}/>
    if (submitData) {
        return (
            <div className='vov'>
                <h3>{text_3_0}</h3>
                <div>{text_3_1} <Badge bg='primary'>{submitData.order.id}</Badge>.</div>
                <div>{text_3_2}</div>
                <hr/>
                <p className="mb-0">{text_3_3}</p>
            </div>
        );
    }

    return (
        <>
            <Stack gap={3}>
                <div id='pdf-source' ref={targetRef}>
                    <Stack gap={3}>
                        {ordersItems.map((order, index) => renderOrder(order, index))}
                    </Stack>
                </div>
                <div className="vov">

                    <Form>
                        <Row className="align-items-center">
                            <Col md="auto">
                                <InputGroup className="mb-2">
                                    <InputGroup.Text id="basic-addon1">@</InputGroup.Text>
                                    <Form.Control
                                        placeholder={text_7_4}
                                        aria-label={text_7_4}
                                        aria-describedby="basic-addon1"
                                        value={userName}
                                        onInput={updateName}
                                    />
                                </InputGroup>
                            </Col>
                            <Col md="auto">
                                <InputGroup className="mb-2">
                                    <InputGroup.Text id="basic-addon1"><Telephone/></InputGroup.Text>
                                    <Form.Control
                                        placeholder={text_7_5}
                                        aria-label={text_7_5}
                                        aria-describedby="basic-addon1"
                                        value={phone}
                                        onInput={updatePhone}
                                    />
                                </InputGroup>
                            </Col>
                            <Col md="auto">
                                <InputGroup className="mb-2">
                                    <InputGroup.Text id="basic-addon1"><Envelope/></InputGroup.Text>
                                    <Form.Control
                                        placeholder={text_7_7}
                                        aria-label={text_7_7}
                                        aria-describedby="basic-addon1"
                                        value={email}
                                        onInput={updateEmail}
                                    />
                                </InputGroup>
                            </Col>
                            <Col md="auto">
                                <Button
                                    className="mb-2"
                                    disabled={!userName || !phone || ordersItems.length < 1 || sendingOrder}
                                    onClick={createOrder}>
                                    {text_7_3}
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </div>

            </Stack>
        </>
    )
        ;
}

export default CartPage;
