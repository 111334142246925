import Card from 'react-bootstrap/Card';
import {Badge} from "react-bootstrap";
import {useEffect, useState} from "react";
import {Link} from "react-router-dom";

function ProductPreviewCard({product, image, onProductSelected}) {
    const [asset, setAsset] = useState(getImagePlaceholder());

    useEffect(() => {
        preloadImage(image).then(setAsset)
    }, []);

    function getImagePlaceholder() {
        const img = new Image();
        img.src = 'https://placehold.co/853x1280'
        return img;
    }

    function preloadImage(src) {
        return new Promise((resolve, reject) => {
            const img = new Image()
            img.onload = function () {
                resolve(img)
            }
            img.onerror = img.onabort = function () {
                reject(src)
            }
            img.src = src
            return img
        })
    }

    function getOverlay() {
        return (
            <Card.ImgOverlay style={{cursor: 'pointer'}}
                             onClick={() => onProductSelected(product.id)}>
                <Badge bg="danger">Hit</Badge>
            </Card.ImgOverlay>
        )
    }

    return (
        <Card style={{marginBottom: '20px', cursor: 'pointer', borderColor: '#c32d75', borderWidth: '3px', textDecoration: 'none'}}
              as={Link} to={`/product/${product.id}`}>
            <Card.Img variant="top" src={asset.src} onClick={() => onProductSelected(product.id)}/>
            {product.isHit ? getOverlay() : ''}
            <Card.Body>
                <Card.Title>{product.name}</Card.Title>
                <Badge bg="primary">{product.article}</Badge>
            </Card.Body>
        </Card>
    );
}

export default ProductPreviewCard;