import React from 'react';
import {createRoot} from 'react-dom/client';
import {Provider} from 'react-redux';
import {store} from './app/store';
import App from './App';
import './scss/app.scss';
import {QueryClient, QueryClientProvider,} from 'react-query';
import {ApolloProvider, ApolloClient, InMemoryCache} from "@apollo/client";
import {Cloudinary} from "@cloudinary/url-gen";

const container = document.getElementById('root');
const root = createRoot(container);
const queryClient = new QueryClient();
const client = new ApolloClient({
    uri: `${process.env.REACT_APP_API_BASE_URL}/graphql`,
    cache: new InMemoryCache()
});

export const cld = new Cloudinary({cloud: {cloudName: 'dy7ic6yp5'}});

root.render(
    <React.StrictMode>
        <ApolloProvider client={client}>
            <Provider store={store}>
                <QueryClientProvider client={queryClient}>
                    <App/>
                </QueryClientProvider>
            </Provider>
        </ApolloProvider>
    </React.StrictMode>
);
