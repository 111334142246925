import React from 'react';
import {Col, Row} from "react-bootstrap";
import {
    text_4_1, text_4_2,
    text_4_2_1,
    text_4_2_2, text_4_3, text_4_4, text_4_5, text_4_6, text_4_7
} from "../../app/text_contacts";

function ContactsPage() {
    return (
        <>
            <Row className="justify-content-md-center">
                <Col md="auto">
                    <div className='vov'>
                        <Row><h2>{text_4_1}</h2></Row>
                        <hr/>
                        <Row><h2>{text_4_2}</h2></Row>
                        <Row><h2>{text_4_3}</h2></Row>
                        <hr/>
                        <Row><h2>{text_4_4}</h2></Row>
                        <Row><h2>{text_4_5}</h2></Row>
                        <Row><h2>{text_4_6}</h2></Row>
                        <hr/>
                        <Row><h2>{text_4_7}</h2></Row>
                    </div>
                </Col>
            </Row>
        </>
    );
}

export default ContactsPage;
