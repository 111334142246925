import React from 'react';
import {Alert, Col, Row} from "react-bootstrap";

function ServerCallAlert({message}) {
    return (
        <Row>
            <Col xs={12}>
                <Alert variant='danger'>
                    {message}
                </Alert>
            </Col>
        </Row>

    );
}

export default ServerCallAlert;
