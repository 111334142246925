import Carousel from 'react-bootstrap/Carousel';
// import {cld} from "../../index";
import {useQuery} from "@apollo/client";
import {MEDIA_LINKS_QUERY} from "../../app/graphql";
import {CardImg, Col, Row, Spinner} from "react-bootstrap";
import React from "react";
import ServerCallAlert from "../../components/ServerCallAlert";
import {text_1_0} from "../../app/text_basic";

function HomeCarousel() {

    const {loading, error, data} = useQuery(MEDIA_LINKS_QUERY, {
        variables: {mediaType: 'CAROUSEL'},
    });

    function renderCarouselItem(mediaLink) {
        // const img = cld.image(mediaLink).quality('auto')
        return (
            <Carousel.Item key={mediaLink}>
                <CardImg src={mediaLink}/>
                {/*<AdvancedImage cldImg={img}/>*/}
            </Carousel.Item>
        );
    }

    if (loading) return (
        <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
        </Spinner>
    );

    if (error) return <ServerCallAlert message={text_1_0}/>

    return (
        <Carousel fade variant="dark">
            {data.mediaLinks.map(ml => renderCarouselItem(ml))}
        </Carousel>
    );
}

export default HomeCarousel;