import React from 'react';
import {Image} from "react-bootstrap";
import ImageViewModal from "../../components/ImageViewModal";

const ProductAssetPreview = ({asset, modal = false}) => {
    const [modalShow, setModalShow] = React.useState(false);

    return (
        <>
            <Image rounded fluid
                   src={asset.mediaUrl}
                   style={{cursor: "pointer"}}
                   onClick={() => setModalShow(true)}/>
            <ImageViewModal
                show={modalShow}
                onHide={() => setModalShow(false)}
                mediaLink={asset.mediaUrl}
            />
        </>
    )
};

export default ProductAssetPreview;
