import React from 'react';
import './App.css';
import {Container} from "react-bootstrap";
import HeaderNavbar from "./features/navbar/header/HeaderNavbar";
import HomePage from "./features/home/HomePage";
import CatalogPage from "./features/catalog/CatalogPage";
import ProductPage from "./features/product/ProductPage";
import AboutPage from "./features/brand/AboutPage";
import DeliveryPage from "./features/delivery/DeliveryPage";
import ContactsPage from "./features/contacts/ContactsPage";
import Footer from "./features/footer/Footer";
import CartPage from "./features/cart/CartPage";
import PartnershipPage from "./features/partnership/PartnershipPage";
import CertificatesPage from "./features/certificates/CertificatesPage";
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import VacanciesPage from "./features/vacancies/VacanciesPage";


function App() {

    return (
        <>
            <Container>
                <Router>
                    <hr/>
                    <HeaderNavbar/>
                    <hr/>
                    <Routes>
                        <Route path="/" element={<HomePage/>}/>
                        <Route path="/about" element={<AboutPage/>}/>
                        <Route path="/catalog" element={<CatalogPage/>}/>
                        <Route path="/partnership" element={<PartnershipPage/>}/>
                        <Route path="/delivery" element={<DeliveryPage/>}/>
                        <Route path="/certificates" element={<CertificatesPage/>}/>
                        <Route path="/contacts" element={<ContactsPage/>}/>
                        <Route path="/vacancies" element={<VacanciesPage/>}/>
                        <Route path="/product/:id" element={<ProductPage/>}/>
                        <Route path="/cart" element={<CartPage/>}/>
                        <Route path="*" element={<h1>No Page</h1>}/>
                    </Routes>
                </Router>
                <hr/>
                <Footer/>
                <hr/>
            </Container>
        </>

    );
}

export default App;
