import {Badge, Col, Container, Row, Spinner, Stack} from "react-bootstrap";
import React, {useState} from "react";
import ProductPreviewCard from "../../components/ProductPreviewCard";
import {getPrimaryMediaFromProduct} from "../../app/utils";
import {useQuery} from "@apollo/client";
import {PRODUCTS_QUERY} from "../../app/graphql";
import {FcNext, FcPrevious} from "react-icons/fc";
import Navbar from "react-bootstrap/Navbar";
import {text_1_3} from "../../app/text_home";
import ServerCallAlert from "../../components/ServerCallAlert";
import {text_1_0} from "../../app/text_basic";


const pageSize = 4;

function ProductHits() {
    const [page, setPage] = useState(0);

    const {loading, error, data} = useQuery(PRODUCTS_QUERY, {
        variables: {input: {pageInput: {page: page, size: pageSize}, isActive: true, isHit: true}},
    });

    function dispatchLoadPrevHitProductsPage() {
        const prevPage = Math.max(0, page - 1);
        setPage(prevPage);

    }

    function dispatchLoadNextHitProductsPage() {
        const totalPages = Math.ceil(data.products.pageDetails.totalCount / pageSize)
        const nextPage = Math.min(page + 1, totalPages - 1);
        setPage(nextPage);

    }

    function renderCarouselItem() {

        return (
            <Row>
                {data.products.content.map((product) =>
                    <Col key={product.id} md={3}>
                        <ProductPreviewCard
                            product={product}
                            image={getPrimaryMediaFromProduct(product).mediaUrl}/>
                    </Col>)}
            </Row>


        )
    }

    if (loading) return (
        <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
        </Spinner>
    );

    if (error) return <ServerCallAlert message={text_1_0}/>
    if (data.products.content?.length === 0) return '';

    return (
        <>
            <Stack gap={3}>
                <Row>
                    <Container>
                        <div className='vov'>
                            <Badge bg="danger"><h2>{text_1_3}</h2></Badge>
                        </div>
                    </Container>
                </Row>
                <Row style={{marginBottom: '20px'}}>
                    <Row>
                        <Col md={1}> <FcPrevious size={50}
                                                 style={{cursor: "pointer"}}
                                                 onClick={() => dispatchLoadPrevHitProductsPage()}/> </Col>
                        <Col md={10}>
                            {renderCarouselItem()}
                        </Col>
                        <Col md={1}> <FcNext size={50}
                                             style={{cursor: "pointer"}}
                                             onClick={() => dispatchLoadNextHitProductsPage()}/> </Col>
                    </Row>
                </Row>
            </Stack>

        </>
    );
}

export default ProductHits;