import React from 'react';
import {Image} from "react-bootstrap";

const simpleStile = {padding: '0.5rem'};
const activeStile = {padding: '0.5rem', backgroundColor: '#C32D75', borderRadius: '1rem'};

const ProductAssetIcon = ({asset, isActive, onClick}) => {

    return (
        <div style={isActive ? activeStile : simpleStile}>
            <Image rounded fluid
                   src={asset.mediaUrl} style={{cursor: "pointer"}}
                   onClick={() => onClick(asset)}
            />
        </div>
    )
};

export default ProductAssetIcon;
