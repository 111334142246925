import React from 'react';
import {Spinner, Stack} from "react-bootstrap";
import MediaLine from "../../components/kit/MediaLine";
import {useQuery} from "@apollo/client";
import {MEDIA_LINKS_QUERY} from "../../app/graphql";

function CertificatesPage() {

    const certificates = useQuery(MEDIA_LINKS_QUERY, {
        variables: {mediaType: 'CERTIFICATES'},
    });

    const awards = useQuery(MEDIA_LINKS_QUERY, {
        variables: {mediaType: 'AWARDS'},
    });

    function renderCertificateSection() {
        if (certificates.loading) return (
            <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
            </Spinner>
        );

        if (certificates.error) return `Error! ${certificates.error}`;

        return (<MediaLine text={'Сертификаты'} md={3}
                           mediaLinks={certificates.data.mediaLinks}
                           modal={true}/>)
    }

    function renderAwardsSection() {
        if (awards.loading) return (
            <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
            </Spinner>
        );

        if (awards.error) return `Error! ${awards.error}`;

        return (
            <MediaLine text={'Достижения'} md={3}
                       mediaLinks={awards.data.mediaLinks}
                       modal={true}/>
        )
    }

    return (
        <>
            <Stack gap={3}>
                {renderCertificateSection()}
                {renderAwardsSection()}
            </Stack>
        </>
    );
}

export default CertificatesPage;
