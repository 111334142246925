import React, {useEffect, useState} from 'react';
import {Col, Row} from "react-bootstrap";
import CatalogPagination from "../pagination/CatalogPagination";
import CatalogFilter from "./CatalogFilter";
import {useQuery} from "@apollo/client";
import {PRODUCTS_QUERY} from "../../app/graphql";
import CatalogContent from "./CatalogContent";
import {useDispatch, useSelector} from "react-redux";
import {selectDataFilter, selectPage, setDataFilter, setPage} from "../../app/appSlice";
import ServerCallAlert from "../../components/ServerCallAlert";
import {text_1_0} from "../../app/text_basic";

const pageSize = 12;

function CatalogPage() {
    const dispatch = useDispatch();
    const page = useSelector(selectPage);
    const dataFilter = useSelector(selectDataFilter);

    const {loading, error, data} = useQuery(PRODUCTS_QUERY, {
        variables: {input: {pageInput: {page: page, size: pageSize}, isActive: true, ...dataFilter}},
    });

    useEffect(() => {
        goToPageTop();
    }, []);

    function incrementPage() {
        dispatch(setPage(page + 1));
        goToPageTop();
    }

    function decrementPage() {
        dispatch(setPage(page - 1));
        goToPageTop();
    }

    function setCurrentPage(page) {
        dispatch(setPage(page));
        goToPageTop();
    }

    function setCurrentFilter(filter) {
        dispatch(setDataFilter(filter));
        dispatch(setPage(0));
        goToPageTop();
    }

    function goToPageTop() {
        window.scroll({
            top: 0,
            left: 0,
            behavior: 'smooth',
        });
    }

    if (error) return <ServerCallAlert message={text_1_0}/>



    return (
        <>
            <Row>
                <Col md={3} className="mb-5">
                    <CatalogFilter onSelect={setCurrentFilter} filter={dataFilter}/>
                </Col>
                <Col md={9}>
                    <CatalogContent products={data?.products.content}
                                    loadingStatus={loading}/>
                    <Row>
                        <Col>
                            <CatalogPagination page={page}
                                               loadingStatus={loading}
                                               pageSize={pageSize}
                                               totalPages={Math.ceil(data ? data.products.pageDetails.totalCount / pageSize : 0)}
                                               onNext={incrementPage}
                                               onPrevious={decrementPage}
                                               onPageSelect={setCurrentPage}
                                               isFirstPage={page === 0}
                                               isLastPage={(page + 1) * pageSize >= data?.products.pageDetails.totalCount}/>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </>
    );
}

export default CatalogPage;
