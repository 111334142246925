import {configureStore} from '@reduxjs/toolkit';
import appReducer from './appSlice';
import { routerReducer } from 'react-router-redux';

export const store = configureStore({
    reducer: {
        routing: routerReducer,
        app: appReducer,
    },
});
