import React from 'react';
import {
    Badge,
    ButtonGroup,
    Col,
    Row,
    Spinner,
    Stack,
    ToggleButton
} from "react-bootstrap";
import {Filter} from "react-bootstrap-icons";
import {addOrRemoveFromArray} from "../../app/utils";
import {text_8_1, text_8_2, text_8_3, text_8_4} from "../../app/text_home";
import {useQuery} from "@apollo/client";
import {PRODUCT_CATEGORIES_QUERY} from "../../app/graphql";
import ServerCallAlert from "../../components/ServerCallAlert";
import {text_1_0} from "../../app/text_basic";

function CatalogPage({
                         onSelect,
                         filter: {
                             isSale = false,
                             isBigSize = false,
                             isBaseModel = false,
                             isSchool = false,
                             productCategoryId = 0,
                             productTypeIds = []
                         }
                     }) {

    const {loading, error, data} = useQuery(PRODUCT_CATEGORIES_QUERY);

    const handleProductCategory = (productCategory) => {
        onSelect({
            isSale,
            isBigSize,
            isBaseModel,
            isSchool,
            productCategoryId: productCategory.id,
            productTypeIds
        });
    }

    const handleProductTypes = (productTypeId) => {
        const pt = addOrRemoveFromArray(productTypeIds, productTypeId, v => v)
        onSelect({
            isSale,
            isBigSize,
            isBaseModel,
            isSchool,
            productCategoryId,
            productTypeIds: pt
        });
    }

    const handleIsSaleFilter = (isSale) => {
        onSelect({
            isSale,
            isBigSize,
            isBaseModel,
            isSchool,
            productCategoryId,
            productTypeIds
        });
    }

    const handleIsBigSizeFilter = (isBigSize) => {
        onSelect({
            isSale,
            isBigSize,
            isBaseModel,
            isSchool,
            productCategoryId,
            productTypeIds
        });
    }

    const handleIsBaseModelFilter = (isBaseModel) => {
        onSelect({
            isSale,
            isBigSize,
            isBaseModel,
            isSchool,
            productCategoryId,
            productTypeIds
        });
    }

    const handleIsSchoolFilter = (isSchool) => {
        onSelect({
            isSale,
            isBigSize,
            isBaseModel,
            isSchool,
            productCategoryId,
            productTypeIds
        });
    }

    function renderBaseFilterButton(content, props) {
        return (
            <ToggleButton
                key={content}
                id={`toggle-check-${props.value}`}
                type="checkbox"
                variant="outline-primary"
                {...props}
            > {content}
            </ToggleButton>
        )
    }

    function renderBaseFilterOptions() {
        return (
            <ButtonGroup vertical>
                {renderBaseFilterButton(text_8_1, {
                    checked: isSale,
                    value: 'isSale',
                    onChange: (e) => handleIsSaleFilter(e.currentTarget.checked)
                })}
                {renderBaseFilterButton(text_8_2, {
                    checked: isBigSize,
                    value: 'isBigSize',
                    onChange: (e) => handleIsBigSizeFilter(e.currentTarget.checked)
                })}
                {renderBaseFilterButton(text_8_3, {
                    checked: isBaseModel,
                    value: 'isBaseModel',
                    onChange: (e) => handleIsBaseModelFilter(e.currentTarget.checked)
                })}
                {renderBaseFilterButton(text_8_4, {
                    checked: isSchool,
                    value: 'isSchool',
                    onChange: (e) => handleIsSchoolFilter(e.currentTarget.checked)
                })}
            </ButtonGroup>
        )
    }

    function getProductTypes() {
        return productCategoryId === 0
            ? []
            : data.productCategories.filter(pc => pc.id === productCategoryId)[0].productTypes;
    }

    function renderProductTypes() {
        return (
            <ButtonGroup vertical>
                {getProductTypes().map(pt => {
                    return renderBaseFilterButton(pt.name, {
                        checked: productTypeIds.find(id => id === pt.id),
                        value: `${pt.id}`,
                        onClick: () => handleProductTypes(pt.id)
                    })
                })}
            </ButtonGroup>
        )
    }

    function renderProductCategoriesFilter() {
        return (
            <ButtonGroup vertical>
                {data.productCategories.map((productCategory, idx) => (
                    <ToggleButton
                        key={productCategory.id}
                        id={`radio-${idx}`}
                        type="radio"
                        variant="outline-primary"
                        name="radio"
                        value={productCategory.id}
                        checked={productCategory.id === productCategoryId}
                        onClick={() => handleProductCategory(productCategory)}
                    >
                        {productCategory.name}
                    </ToggleButton>
                ))}
            </ButtonGroup>
        )
    }

    if (loading) return (
        <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
        </Spinner>
    );

    if (error) return <ServerCallAlert message={text_1_0}/>

    return (
        <Stack gap={3}>
            {/*<Row>*/}
            {/*    <Col>*/}
            {/*        <hr/>*/}
            {/*    </Col>*/}
            {/*    <Col md='auto'><h4><Badge disabled={true}><Filter/></Badge></h4></Col>*/}
            {/*    <Col>*/}
            {/*        <hr/>*/}
            {/*    </Col>*/}
            {/*</Row>*/}
            <div className='vov'>
                <Row>
                    <Col>
                        <Row>{renderBaseFilterOptions()}</Row>
                    </Col>
                </Row>
            </div>
            <div className='vov'>
                <Row>
                    {renderProductCategoriesFilter()}
                </Row>
            </div>
            {productCategoryId !== 0
                ? <div className='vov'>
                    <Row>
                        <Col>
                            <Row>{renderProductTypes()}</Row>
                        </Col>
                    </Row>
                </div>
                : ''}

        </Stack>
    );
}

export default CatalogPage;
