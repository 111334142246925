import React from 'react';
import {Col, Row} from "react-bootstrap";
import ProductPreviewCard from "../../components/ProductPreviewCard";
import {useDispatch} from "react-redux";
import {loadProduct} from "../../app/appSlice";

const areEqual = (prevProps, nextProps) => {
    return nextProps.loadingStatus;
};

const CatalogContent = ({products = []}) => {
    const dispatch = useDispatch();

    function renderProductPreviewCard(product) {
        return (
            <Col key={product.id} xs={6} md={4} lg={3}>
                <ProductPreviewCard product={product}
                                    image={product.primaryAsset?.mediaUrl}
                                    onProductSelected={id => dispatch(loadProduct(id))}
                />
            </Col>
        )
    }

    return (
        <Row>
            {products.map((product) => renderProductPreviewCard(product))}
        </Row>
    );
};

export default React.memo(CatalogContent, areEqual);
