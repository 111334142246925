import React, {useEffect, useState} from 'react';
import {Col, Row, Stack, Badge, Spinner} from "react-bootstrap";
import {useDispatch, useSelector} from "react-redux";
import {addOrder, refreshOrder, removeOrder, selectOrders} from "../../app/appSlice";
import {useQuery} from "@apollo/client";
import {PRODUCT_QUERY} from "../../app/graphql";
import {selectProductId} from "../../app/appSlice";
import ProductColorSelector from "./ProductColorSelector";
import ProductCartControl from "./ProductCartControl";
import {asChunksList, mapValueReplacer, mapValueReviver} from "../../app/utils";
import ProductAssetPreview from "./ProductAssetPreview";
import ProductAssetIcon from "./ProductAssetIcon";
import {text_6_1} from "../../app/text_home";
import ServerCallAlert from "../../components/ServerCallAlert";
import {text_1_0} from "../../app/text_basic";
import {useParams} from "react-router";
import ImageViewModal from "../../components/ImageViewModal";

function ProductPage() {
    const dispatch = useDispatch();
    const {id} = useParams();
    const productId = useSelector(selectProductId)
    const order = useSelector(selectOrders).filter(order => {
        return order.productId === productId
    })[0];

    const [activeAsset, setActiveAsset] = useState();
    const [selected, setSelected] = useState(order ? JSON.parse(order.selected, mapValueReviver) : new Map());

    const {loading, error, data} = useQuery(PRODUCT_QUERY, {variables: {id: Number.parseInt(id)}});

    useEffect(() => {
        goToPageTop();
    }, []);

    function goToPageTop() {
        window.scroll({
            top: 0,
            left: 0,
            behavior: 'smooth',
        });
    }

    function pushToColorChecked({assetId, sizeId, amount}) {
        if (amount === '') {
            selected.get(assetId).delete(sizeId);
            if (selected.get(assetId).size === 0) {
                selected.delete(assetId)
            }
        } else {
            const map = selected.get(assetId) ? selected.get(assetId) : new Map();
            selected.set(assetId, map.set(sizeId, amount))
        }
        setSelected(new Map(selected))
    }

    function onCartAdd() {
        dispatch(addOrder({productId: data.product.id, selected: JSON.stringify(selected, mapValueReplacer)}))
    }

    function onCartRefresh() {
        dispatch(refreshOrder({productId: data.product.id, selected: JSON.stringify(selected, mapValueReplacer)}))
    }

    function onCartDelete() {
        dispatch(removeOrder(data.product.id))
    }

    function renderIconsSection() {
        return (
            <Stack gap={3}>
                {asChunksList(data.product.assets.filter(asset => !asset.isColor || asset.isPrimary), 2)
                    .map((productMediasChunk, index) => (
                        <Row key={index}>
                            {productMediasChunk.map(asset =>
                                <Col key={asset.id} md={12}>
                                    <ProductAssetIcon asset={asset} onClick={setActiveAsset}/>
                                </Col>)
                            }
                        </Row>
                    ))}
                <hr/>
            </Stack>
        )
    }

    function renderProductInfoSection() {
        return (
            <>
                <Row>
                    <Col>
                        <Badge bg="primary">{data.product.article}</Badge>
                    </Col>
                </Row>
                <Row><h2>{data.product.name}</h2></Row>
                <Row><p>{data.product.description}</p></Row>
            </>
        )
    }

    function getColorAssets() {
        return data.product.assets.filter(asset => asset.isColor && asset.isActive)
    }

    if (loading) return (
        <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
        </Spinner>
    );

    function getActiveAsset() {
        return activeAsset || data.product.assets.filter(media => media.isPrimary)[0];
    }

    if (error) return <ServerCallAlert message={text_1_0}/>

    return (
        <Row>
            <Col lg={4} xs={8}>
                <ProductAssetPreview asset={getActiveAsset()} modal={true}/>
            </Col>
            <Col lg={1} xs={4}>
                {renderIconsSection()}
            </Col>
            <Col lg={7}>
                {renderProductInfoSection()}
                <Stack gap={3}>
                    <ProductColorSelector
                        values={getColorAssets()}
                        active={selected}
                        onAssetSelected={setActiveAsset}
                        onAmountInput={color => {
                            pushToColorChecked(color);
                        }}/>
                </Stack>
                <hr/>
                <Row>
                    <ProductCartControl order={order}
                                        onCartAdd={onCartAdd}
                                        onCartRefresh={onCartRefresh}
                                        onCartDelete={onCartDelete}
                                        disabled={!selected.size > 0}/>
                </Row>
            </Col>
        </Row>
    );
}

export default ProductPage;
