export const text_1_1 = 'Доставка и оплата'
export const text_1_2 = 'С кем работаем:'
export const text_1_3 = 'Юридические лица, индивидуальные предприниматели, организаторы СП и физические лица .'
export const text_1_4 = 'Интернет - площадки  (предоставляем загрузочный файл со всеми свойствами, а также фотографии изделий).'

export const text_2_1 = 'Система скидок:'
export const text_2_2 = 'Действует система скидок от объёма , также система накопительных скидок .'
export const text_2_3 = 'Товар можно приобрести за безналичный расчёт.'
export const text_2_4 = 'Наша компания предоставляет полный пакет документов на товар (накладные, сертификаты, кассовые чеки).'

export const text_3_1 = 'Как упакована наша продукция:'
export const text_3_2 = 'Каждое изделие в индивидуальной упаковке с маркировкой, согласно требованиям РФ'

export const text_4_1 = 'Заказ через менеджера'
export const text_4_2 = 'Для того, чтобы купить трикотаж оптом , вам необходимо сделать следующее :'
export const text_4_3 = '1. Направить заявку с указанием необходимых реквизитов . Для юридических лиц это: наименование фирмы КПП, ОКПО, юридический адрес, банковские реквизиты. Для индивидуальных предпринимателей фамилия ИНН, паспортные данные и адрес регистрации по месту жительства. В заявке указать желаемый ассортимент и количество товара. Если нужен отдельный договор поставки, дополнительно необходимо выслать ксерокопии регистрационных документов копии страниц паспорта.'
export const text_4_4 = '2. Заявку можно отправить с сайта,  воспользовавшись формой, расположенной в разделе контакты. Или написать электронное письмо на почту lemua_00@mail.ru'
export const text_4_5 = '3. Вам будет выслан счет на оплату по электронной почте. Оригинал счета получите вместе с товаром.'
export const text_4_6 = '4. Сроки обработки заказа составляет 7-10 рабочих дней.'
export const text_4_7 = '5. Товар отгружается покупателю после поступления денег на расчетный счет (100% предоплата).'
export const text_4_8 = '6. Отгрузка товара сопровождается товарной накладной по форме Торг-12 , транспортной накладной, копиями документов о соответствии.'
export const text_4_9 = '7. Заказы  с сайта будут доступны позже.'
export const text_4_10 = 'Доставка осуществляется почтой : donbass-post. ru, и любыми удобными для Вас  транспортными компаниями.'


