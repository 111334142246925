import {Badge, InputGroup} from "react-bootstrap";
import React from "react";
import Button from "react-bootstrap/Button";

function IconButton({icon, isBadge, content, ...props}) {
// return ''
    return (
        <InputGroup>
            <InputGroup.Text id="btnGroupAddon">{icon}</InputGroup.Text>
            <Button
                {...props}>
                {isBadge ? <Badge>{content}</Badge> : content}
            </Button>
        </InputGroup>
    );
}

export default IconButton;