export const text_3_0 = 'Благодарим!'
export const text_3_1 = 'Ваша заявка была успешно сформирована под номером'
export const text_3_2 = 'В скором времени наш менеджер свяжится с Вами, чтобы уточнить детали.'
export const text_3_3 = 'Если возникнут дополнительные вопросы, пожалуйста посетит раздел контактов, чтобы связатся с нами'
export const text_3_4 = 'Формирование заявки...'
export const text_3_5 = 'Загрузка корзины...'

export const text_7_3 = 'Отправить заказ'
export const text_7_4 = 'Имя'
export const text_7_5 = 'Телефон'
export const text_7_6 = 'Изменить'
export const text_7_7 = 'e-mail'