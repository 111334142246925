import {gql} from "@apollo/client";

export const PRODUCTS_QUERY = gql`
    query ($input: ProductFilterInput) {
        products(input: $input) {
            pageDetails {
                currentPage
                totalCount
            }
            content {
                id
                name
                isHit
                article
                primaryAsset {
                    id
                    mediaUrl
                }
            }
        }
    }
`;

export const PRODUCTS_CART_QUERY = gql`
    query ($input: ProductFilterInput) {
        products(input: $input) {
            pageDetails {
                currentPage
                totalCount
            }
            content {
                id
                name
                article
                assets {
                    id
                    mediaUrl
                    sizes {
                        id
                        size
                    }
                }
            }
        }
    }
`;

export const PRODUCT_QUERY = gql`
    query ($id: Int!) {
        product(id: $id) {
            id
            name
            description
            isHit
            article
            assets {
                id
                isPrimary
                mediaUrl
                isColor
                isActive
                sizes {
                    id
                    size
                    isActive
                }
            }
        }
    }
`;

export const PRODUCT_CATEGORIES_QUERY = gql`
    {
        productCategories {
            id
            name
            productTypes {
                id
                name
            }
        }
    }
`;

export const ORDER_MUTATION = gql`
    mutation ($input: OrderInput) {
        order(input: $input) {
            id
        }
    }
`;

export const MEDIA_LINKS_QUERY = gql`
    query ($mediaType: MediaType) {
        mediaLinks(mediaType: $mediaType)
    }
`;