export function addOrRemoveFromArray(array, value, valueExtractor) {
    return array.find(v => valueExtractor(v) === valueExtractor(value))
        ? array.filter(v => valueExtractor(v) !== valueExtractor(value))
        : [...array, value]
}

export function getPrimaryMediaFromProduct(product) {
    return product.assets.find(media => media.isPrimary === true);
}

export function asChunksList(items, chunkSize) {
    const chunks = [];
    for (let i = 0; i < items.length; i += chunkSize) {
        const chunk = items.slice(i, i + chunkSize);
        chunks.push(chunk);
    }
    return chunks;
}

export function mapValueReplacer(key, value) {
    if(value instanceof Map) {
        return {
            dataType: 'Map',
            value: Array.from(value.entries()), // or with spread: value: [...value]
        };
    } else {
        return value;
    }
}

export function mapValueReviver(key, value) {
    if(typeof value === 'object' && value !== null) {
        if (value.dataType === 'Map') {
            return new Map(value.value);
        }
    }
    return value;
}