export const text_1 = 'Оптовые продажи трикотажных изделий от производителя. Весь товар сертифицирован.'
export const text_1_2_1 = 'Лемуа - производитель трикотажных изделий для всей семьи. В современном мире, где качество и комфорт одежды играют решающую роль в нашей повседневной жизни. Лемуа занимает лидирующее положение в производстве трикотажных изделий для всей семьи. Наша фабрика, основанная на принципах профессионализма и инноваций, предлагает широкий ассортимент высококачественной продукции, которая соответствует всем требованиям и стандартам.'
export const text_1_3 = 'Хиты продаж'
export const text_1_4 = 'Новинки'

export const menu_btn_1 = 'О фабрике'
export const menu_btn_2 = 'Каталог'
export const menu_btn_3 = 'Сотрудничество'
export const menu_btn_4 = 'Доставка и оплата'
export const menu_btn_5 = 'Контакты'
export const menu_btn_6 = 'Сертификаты'
export const menu_btn_7 = 'Вакансии'

export const text_2_1 = 'Наша фабрика предлагает высококачественную продукцию, которая соответствует всем требованиям и стандартам. Мы гарантируем качество и безопасность нашей продукции, используя современное оборудование и цифровые технологии. Приобретая нашу продукцию, вы можете быть уверены в ее качестве и комфорте.'
export const text_2_2 = 'Профессиональная команда и современное оборудование'
export const text_2_3 = 'Лемуа - это команда высококвалифицированных специалистов с многолетним опытом в производстве трикотажных изделий. Мы используем современное оборудование и цифровые технологии, которые позволяют нам создавать продукцию высокого качества и комфорта. Наша команда постоянно совершенствует свои навыки и знания, чтобы быть в курсе последних тенденций и требований рынка.'
export const text_2_4 = 'Качество и безопасность'
export const text_2_5 = 'Лемуа - это гарантия качества нашей продукции. Мы соблюдаем все требования и стандарты при изготовлении, особенно детской одежды. Мы используем полотна с высоким содержанием натуральных волокон, которые обеспечивают комфорт и безопасность для кожи. Наша продукция проходит строгий контроль качества на всех этапах производства, чтобы гарантировать, что она соответствует нашим высоким стандартам.'
export const text_2_6 = 'Ассортимент и инновации'
export const text_2_7 = 'Лемуа регулярно обновляет ассортимент нашей продукции, чтобы соответствовать меняющимся требованиям и тенденциям рынка. Мы предлагаем широкий выбор трикотажных изделий для всей семьи, от детской одежды до взрослой. Наша команда постоянно работает над созданием новых и интересных дизайнов, которые сочетают в себе комфорт, стиль и качество.'
export const text_2_8 = 'Заключение'
export const text_2_9 = 'Лемуа - это лидер в производстве трикотажных изделий для всей семьи. Наша фабрика предлагает высококачественную продукцию, которая соответствует всем требованиям и стандартам. Мы гарантируем качество и безопасность нашей продукции, используя современное оборудование и цифровые технологии.'

export const text_5_1 = ' +7 (949)322-70-18'
export const text_5_2 = 'ЛЕМУА Производство и реализация домашнего трикотажа'
export const text_5_3 = ' lemua_00@mail.ru'
export const text_5_4 = 'https://t.me/LemyaEN'

export const text_6_1 = 'Выбор цвета:'

export const text_8_1 = 'Распродажа'
export const text_8_2 = 'Большой размер'
export const text_8_3 = 'Базовая модель'
export const text_8_4 = 'Школа'

export const text_10_1 = 'Добавить в корзину'
export const text_10_2 = 'Удалить из корзины'
export const text_10_3 = 'Обновить в корзине'
