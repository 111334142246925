import React from 'react';
import {Badge, Col, Form, InputGroup, Row, Stack} from "react-bootstrap";
import {text_6_1} from "../../app/text_home";
import ProductAssetIcon from "./ProductAssetIcon";

const ProductColorSelector = ({values = [], active = [], onAmountInput, onAssetSelected}) => {

    const [activeColorAsset, setActiveColorAsset] = React.useState(values[0]);

    function handleAmountInput(assetId, sizeId, e) {
        const amount = e.target.value;
        if (Number.parseInt(amount)) {
            onAmountInput({assetId, sizeId, amount})
        } else if (amount === '') {
            onAmountInput({assetId, sizeId, amount})
        }
    }

    function onColorIconClick(asset) {
        setActiveColorAsset(asset);
        onAssetSelected(asset)
    }

    function renderColorIconsSection() {
        return values.map((asset) => (
            <Col key={`color-preview-${asset.id}`} md={2} xs={6} style={{marginBottom: '1rem'}}>
                <ProductAssetIcon asset={asset} isActive={activeColorAsset?.id === asset.id}
                                  onClick={() => onColorIconClick(asset)}/>
            </Col>)
        )

    }

    function renderSize(assetId, size) {
        const value = active.get(assetId)?.get(size.id) || '';

        return (

            <Col md='4' key={size.id}>
                <InputGroup style={{marginBottom: '.5rem'}}>
                    <InputGroup.Text id="basic-addon1">
                        <Badge bg={value ? 'primary' : 'dark'}>{size.size}</Badge>
                    </InputGroup.Text>
                    <Form.Control
                        disabled={!size.isActive}
                        aria-describedby="basic-addon1"
                        value={value}
                        onInput={e => handleAmountInput(assetId, size.id, e)}
                    />
                </InputGroup>
            </Col>
        )
    }

    function renderContent() {
        return values.filter(asset => asset.id === activeColorAsset.id).map(asset =>
            <Row key={asset.id}>
                <Col md={12}>
                    <Row>
                        {asset.sizes.map(size => renderSize(asset.id, size))}
                    </Row>
                </Col>
            </Row>
        )
    }

    return (
        <div className='vov'>
            <Stack gap={3}>
                <Row>
                    <Col>
                        {text_6_1}
                    </Col>
                </Row>
                <Row>
                    {renderColorIconsSection()}
                </Row>
            </Stack>
            <Stack gap={3}>
                {renderContent()}
            </Stack>
        </div>
    )
};

export default ProductColorSelector;
