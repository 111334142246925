import React from 'react';
import {Col, InputGroup, Row} from "react-bootstrap";
import {text_10_1, text_10_2, text_10_3} from "../../app/text_home";
import Button from "react-bootstrap/Button";
import {ArrowClockwise, CartPlus, CartX} from "react-bootstrap-icons";


const ProductCartControl = ({order, onCartAdd, onCartRefresh, onCartDelete, disabled = true}) => {

    function renderAddToCart() {
        return (
            <Col>
                <InputGroup>
                    <InputGroup.Text id="btnGroupAddon"><CartPlus/></InputGroup.Text>
                    <Button
                        disabled={disabled}
                        onClick={onCartAdd}>
                        {text_10_1}
                    </Button>
                </InputGroup>
            </Col>
        )
    }

    function renderEditToCart() {
        return (
            <Row className="justify-content-between">
                <Col sm="auto">
                    <InputGroup className="mb-2">
                        <InputGroup.Text id="btnGroupAddon"><ArrowClockwise/></InputGroup.Text>
                        <Button
                            disabled={disabled}
                            onClick={onCartRefresh}>
                            {text_10_3}
                        </Button>
                    </InputGroup>
                </Col>
                <Col sm="auto">
                    <InputGroup className="mb-2">
                        <InputGroup.Text id="btnGroupAddon"><CartX/></InputGroup.Text>
                        <Button
                            variant="danger"
                            onClick={onCartDelete}>
                            {text_10_2}
                        </Button>
                    </InputGroup>
                </Col>
            </Row>
        )
    }

    return (
        <Col>
            {order ? renderEditToCart() : renderAddToCart()}
        </Col>
    )
};

export default ProductCartControl;
